.upload__image-wrapper {

  .upload-container {
    width: 100%;
    height: 125px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed no-repeat center center ;
    background-size: contain;
    position: relative;

    .remove-button {
      position: absolute;
      right: 10px;
    }
  }
}
