body {
  padding-bottom: 88px;
}

body,
input,
.form-control,
.form-select,
.btn,
.dropdown-menu {
  font-size: 13px;
}

.btn-sm {
  padding: 0 5px;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

input,
.form-control,
.form-select {
  padding: 5px;
}

td {
  white-space: nowrap;
}

.app-container {
}
