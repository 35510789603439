.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & > .container {
    width: 350px;
  }

  .license-container {
    position: absolute;
    right: 15px;
    top: 15px;
    color: darkgray;
    cursor: pointer;
  }
}
